import React from 'react'
import Navbar from './Navbar';
import {BiSupport}  from 'react-icons/bi';
import './Support.css'

function Support({username}) {
    return (
     <div>
        <Navbar username={username}/>
        <div className="support_text">
         <a>Need Help? </a>
        
        </div>
        <BiSupport className="support_icon"> </BiSupport>
        <div className="support_mail">
            <br></br>
            <a className = "email"
                href = "mailto:change@packetfanetic.com">
                    contact us
            </a>
        </div>
        <div className= "description">
            <br/>
            For any issues reach out to change@packetfanetic.com
            <br/>
            We will get back to you within 24-48 hours
        </div>

    </div>
    )
}

export default Support