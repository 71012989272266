import firebase from "firebase";


const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyClrOS2ThJ0_v58twecdbma1qV1pGTSn6I",
  authDomain: "ordee-f29ee.firebaseapp.com",
  databaseURL: "https://ordee-f29ee-default-rtdb.firebaseio.com",
  projectId: "ordee-f29ee",
  storageBucket: "ordee-f29ee.appspot.com",
  messagingSenderId: "1078446880018",
  appId: "1:1078446880018:web:497f60f50b41344c716fcb",
  measurementId: "G-VCSW2X256M"
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage(); 

export {db,auth,storage};