import React from 'react';
import {Link} from 'react-router-dom'
import "./ErrorPage.css";


function ErrorPage() {
    return(
        <div>
        <div class="error_main">  
        <h2> 404 </h2>
        <h2>Page not found</h2>
        <img className='error_image' src="./error.gif" alt=""></img>
        </div>
        <button className="Error_Home" >
        <Link to ="/">Home</Link>
        </button>
        </div>
    )
    
}

export default ErrorPage;