import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import firebase from "firebase";
import Navbar from "./Navbar";
import "./Settings.css";
import { saveAs } from "file-saver";


function Settings({ username, index }) {
  const [number, setnumber] = useState(0);
  const [posts, setPosts] = useState([]);
  let id;
 
  useEffect(() => {
    db.collection("Restaurant")
      .doc(username.uid)
      .collection("Branch")
      .get()
      .then((res) => setnumber(res.size));

    id = number;
  });
  useEffect(() => {
    db.collection("Restaurant")
      .doc(username.uid)
      .collection("Branch")
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setPosts(
          snapshot.docs.map((docu) => ({
            id: docu.id,
            card: docu.data(),
          }))
        );
      });
  }, []);

  const Add_Card = (event) => {
    id++;
    var Table_name="Table" + id
    setnumber(number + 1);
    event.preventDefault();
    return db
      .collection("Restaurant")
      .doc(username.uid)
      .collection("Branch")
      .doc(Table_name)
      .set({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        table: "",
        id: id,
        order_time :  new firebase.firestore.Timestamp(1, 0),
      });
  };
  const Delete_Card = () => {
    // console.log(id);
    let index = db
      .collection("Restaurant")
      .doc(username.uid)
      .collection("Branch")
      .where("id", "==", id);
    index.get().then(function (Snapshot) {
      Snapshot.forEach(function (doc) {
        doc.ref.delete();
        // console.log(doc.ref);
      });
    });
  };

  const download = () => {
    var url =
      "https://firestore.googleapis.com/v1/projects/ordee-f29ee/databases/(default)/documents";
    var user = username.uid;
    var content = "restaurant='" + url + "/Restaurant/" + user + "/Branch/'";
    // any kind of extension (.txt,.cpp,.cs,.bat)
    var filename = "restaurant_info.py";

    var blob = new Blob([content], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, filename);
  };

  return (
    <div className="settings">
      <Navbar username={username} />
      <div>
        <h1 className="settings_table_count">
          You currently have {number} tables in your restaurant{" "}
        </h1>
           
        <div class="menu">
        <input type="checkbox" id="toggle" className= "checkbox1"/>
        <label id="show-menu" for="toggle">
        <div class="btn2">
        <e class="menuBtn">Menu</e>
        <e class="closeBtn">Close</e>
        </div>
        <div class="btn2">
        <button  class="settings_btn" onClick={Add_Card}> Add Table </button>
        </div>
        <div class="btn2">
        <button class="settings_btn" onClick={Delete_Card}> Delete Table </button>
        </div>
        <div class="btn2">
        <button class="settings_btn"onClick={download}> Download </button>
        </div>
        </label>
        </div>
      </div>
    </div>
  );
}

export default Settings;
