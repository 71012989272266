import React, { useState, useEffect } from "react";
import "./Home.css";
import moment from "moment";

function HomeQueue({ id, time, order_list }) {
  const [Time, setTime] = useState(time);
  console.log(id);
  useEffect(() => {
    const interval = setInterval(
      () => setTime(moment(Date.now()).diff(moment(time.toDate()), "seconds")),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  if (300 - Time >= 200) {
    return (
      <div>
        <div>
          <div className="object">
            <a href={`#${id}`}>
              <div className="settings_icon" title={order_list}>
                <p className="settings_table_no"> {id} </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  } else if (300 - Time < 200 && 300 - Time >= 100) {
    return (
      <div>
        <div>
          <div className="object">
            <a href={`#${id}`}>
              <div className="settings_icon_yellow" title={order_list}>
                <p className="settings_table_no"> {id} </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <div className="object">
            <a href={`#${id}`}>
              <div className="settings_icon_timeup" title={order_list}>
                <p className="settings_table_no"> {id} </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeQueue;
