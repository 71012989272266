import React, { useState, useEffect } from "react";
import { db, auth } from "./firebase";
import ErrorPage from "./ErrorPage";
import firebase from "firebase";
import "./LoginForm.css";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import Settings from "./Settings";
import Support from "./Support";
import { FaGoogle } from "react-icons/fa";
import Password__Strength from "./Password__Strength";
import Lottie from "react-lottie";
import animationData from "./Animations/no_internet";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  var pattern = new RegExp(/^[0-9\b]+$/);
  const [error_msg, seterror_msg] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [user, setUser] = useState(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (error_msg.length > 0) {
    setTimeout(function () {
      seterror_msg("");
    }, 5000);
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user]);

  const Login = (event) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      seterror_msg("");
      let errorCode = error.code;
      if (
        errorCode === "auth/internal-error" ||
        errorCode === "auth/invalid-email"
      ) {
        seterror_msg("Invalid Email!!");
        setEmail("");
        setPassword("");
      } else if (errorCode === "auth/wrong-password") {
        seterror_msg("Incorrect Password");
        setPassword("");
      } else if (errorCode === "auth/user-not-found") {
        seterror_msg("New User? Sign-Up");
        setPassword("");
        setEmail("");
      }
    });
  };
  const SignUp = (event) => {
    event.preventDefault();
    if (!pattern.test(phone) | (phone.length !== 10)) {
      setPhone("");
      setPassword("");
      setPassword1("");
      seterror_msg("Please enter a valid phone number");
    } else if (password.length < 6) {
      setPassword("");
      setPassword1("");
      seterror_msg("Please use a strong password, min. 6 characters");
    } else if (password !== password1) {
      seterror_msg("Passwords do not match");
      setPassword("");
      setPassword1("");
    } else if (username === "") {
      seterror_msg("Please Enter you Name");
    } else {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((cred) => {
          return db.collection("Restaurant").doc(cred.user.uid).set({
            Name: username,
            Phone: phone,
            Email: email,
            Address1: "",
            Address2: "",
            State: "",
            Country: "",
            PostalCode: "",
          });
        })
        .catch((error) => {
          seterror_msg("");
          let errorCode = error.code;
          if (errorCode === "auth/email-already-in-use") {
            seterror_msg("Email Already Exists!");
            setEmail("");
            setPassword("");
            setPassword1("");
          } else if (errorCode === "auth/invalid-email") {
            seterror_msg("Please Enter a Valid mail id");
            setEmail("");
            setPassword("");
            setPassword1("");
          } else if (
            (errorCode === "auth/invalid-password") |
            (errorCode === "auth/weak-password")
          ) {
            seterror_msg("Enter a Strong Password");
            setPassword("");
            setPassword1("");
          }
        });
    }
  };

  const google = (event) => {
    event.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        return db.collection("Restaurant").doc(result.user.uid).set({
          Name: user.displayName,
          Phone: "",
          Email: user.email,
          Address1: "",
          Address2: "",
          State: "",
          Country: "",
          PostalCode: "",
        });
      })
      .catch((error) => alert(error.message));
  };
  const signUpButton = () => {
    document.getElementById("container").classList.add("right-panel-active");
    seterror_msg("");
  };

  const signInButton = () => {
    document.getElementById("container").classList.remove("right-panel-active");
    seterror_msg("");
  };
  if (navigator.onLine) {
    return (
      <div>
        {user ? (
          user.email === "a@g.com" ? (
            <Redirect to="/Admin" />
          ) : (
            <Router>
              <Switch>
                <Route exact path="/Settings" component={Settings}>
                  <Settings username={user} />
                </Route>
                <Route exact path="/Profile" component={Profile}>
                  <Profile user1={user} />
                </Route>
                <Route exact path="/Support" component={Support}>
                  <Support username={user} />
                </Route>

                <Route exact path="/" component={Home}>
                  <Home user={user} username={user} />
                </Route>
                <Route path="/*">
                  <ErrorPage />
                </Route>
              </Switch>
            </Router>
          )
        ) : (
          <div className="container" id="container">
            <div className="form-container sign-up-container">
              <form action="#">
                <h1>Create Account</h1>

                <input
                  type="text"
                  placeholder="Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Contact Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Retype Password"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                />
                {password ? <Password__Strength password={password} /> : null}
                <button className="Signup_button" onClick={SignUp}>Sign Up</button>
                <div className="error"> {error_msg}</div>
                <a> or sign in with Google </a>
                <div className="soical">
                  <ul class="social">
                    <li class="social">
                      <a href="#">
                        <h3 >
                          <FaGoogle /> 
                        </h3>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="faGoogle" onClick={google}></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            <div className="form-container sign-in-container">
              <form action="#">
                <h1>Sign in</h1>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a>
                  <Link to="/Password__Reset">Forgot your password?</Link>
                </a>
                <button onClick={Login}>Sign in</button>
                <div className="error"> {error_msg}</div>
                <a> or sign up with Google </a>
                <div className="soical">
                  <ul class="social">
                    <li class="social">
                      <a href="#">
                        <h3 >
                          <FaGoogle /> 
                        </h3>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="faGoogle" onClick={google}></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your details
                  </p>
                  <button className="ghost" id="signIn" onClick={signInButton}>
                    {/*check for id here as refreshing the page does not support animation and displays error */}
                    Sign In
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello,</h1>
                  <p>Enter your details here and get on board with us</p>
                  <button
                    className="ghost"
                    id="container"
                    onClick={signUpButton}
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="offline">
        <Lottie options={defaultOptions} height={400} width={400} />
        <div className="no_internet_text">
          You need an active internet connection to keep using my services!
        </div>
      </div>
    );
  }
}
export default LoginForm;