import React, { useState} from "react";
import { Link } from "react-router-dom";
import "./Password_reset.css";
import { auth } from "./firebase";
 
function Password__reset() {
  const [email, setEmail] = useState("");
  const [msg,setMsg]=useState("");

  if(msg.length>0)
  {
    setTimeout(function(){ setMsg("") }, 7000);
  }
  const Send__link = (event) => {
    event.preventDefault();

    if (email !== "") {
      auth
        .sendPasswordResetEmail(email)
        .then(function () {
          setMsg("Kindly check your mail for a link to reset your password");
          setEmail("")
        })
        .catch((error) => setMsg(error.message));
    } else {
      setMsg("Email field empty");
    }
  };
  return (
    <div className="password_reset">
    <div>
        <img
        className="pf_logo"
        src={"./pf_circle.png"}
        alt="" />
    </div>
    <div className="fp">
      <div className="fp_header">
      <img 
             className="fp_logo" 
             src={"./ordee2.png"} 
             alt="" />
        </div>
      
      <div className ="reset_desc">
        To reset the password for your account, please provide us with your registered email id.
      </div>
      <input 
        className="password_reset_email"
        id="test"
        type="email"
        placeholder="Enter Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br></br>
      <button onClick={Send__link}>
        <a1>Send Reset Link</a1>
        </button>
      <br></br>
      <button> 
        <Link to="/">
          <a1>Back to Sign in</a1>
          </Link>
      </button>
      <div>
        
      </div>
      <div className ="reset_msg">
          {msg}
        </div>
    </div>
    </div>
  );
}

export default Password__reset;
