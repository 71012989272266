import React, { useState, useEffect } from "react";
import Cardsflip from "./Cardsflip";
import { db } from "./firebase";
import moment from "moment";
import "./Cards.css";
import { useStateValue } from "./StateProvider";

function Cards({ user }) {
  const [posts, setPosts] = useState([]);
  const [resty] = useStateValue();
  var current = new Date();
  var now = moment(current)
  
  const [Time, setTime] = useState(Date.now());

  useEffect(() => {
    
    db.collection("Restaurant")
      .doc(user.uid)
      .collection(resty.rest)
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        setPosts(
          snapshot.docs.map((docu) => ({
            id: docu.id,
            card: docu.data(),
            orders : [],
            differ : now.diff(moment(docu.data().order_time.toDate()), 'seconds'),
          }))
        );
    
      });
      const interval = setInterval(() => setTime(Date.now()), 1000);
  return () => {
    clearInterval(interval);
  };
  }, []);
 
  return (
    <div className="grid">
      {posts.map(({ card, id ,differ}) => {
         card.orders=card.table.split(',');
          let time = 300 -differ // timers's initial remaining time;
            if(time<0)
              {
                time=1;   // avoiding negative values
               }
           
            return(
              <div className="outer">  
                
                   { card.orders.slice(0).reverse().map((text) => (
                    <Cardsflip order={text} id={card.id} index={id} user={user} className="inner" total = {card.orders.length}  time = {time} order_list = {card.orders}/>
                 ))}
                </div>
                    );
        
      })}
    </div> 
  );
}

export default Cards;