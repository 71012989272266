import React, { useState, useEffect } from "react";
import "./Cards.css";
import firebase from "firebase";
import { db } from "./firebase";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {IconButton, makeStyles } from '@material-ui/core';






function Image_card({image,id,order,user,index,total,time,order_list}) {
    const [isactive, setactive] =useState(false)
    const [color,setColor] = useState("#d5d5d5");
    let key=2;
    
    const deletes = () => {
  
      if(total===1)
      {
      db.collection("Restaurant")
        .doc(user.uid)
        .collection("Branch")
        .doc(index)
        .update({
          "table" : "",
          order_time :  new firebase.firestore.Timestamp(1, 0),
        })
      }
      else
      {
        let i = order_list.indexOf(order);
        order_list.splice(i, 1);
        var del=order_list.join();
        db.collection("Restaurant")
        .doc(user.uid)
        .collection("Branch")
        .doc(index)
        .update({
          "table" : del
        })
        return false;
      }
    };
      const toggle = () => {
          setactive(!isactive);
        }
        const swap = () => {
          setColor("#FF0000");
        }
      const useStyles = makeStyles({
          root: {
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
        })

      const classes = useStyles();
if(total===1)
{
    return (
        <div>
          
            <div className ="card" id = {id} title={order}>
         <img
         className="image"
         src={image}
         alt=""
         onClick={() => {
          if (order.length>0) {
              setactive(!isactive);
         }
         }}
       />
        {/* shows the table number */}
        <h1 className = "img_number"> {id}
        {/* <div className={`multiple_${total>1? "visible" : "hidden"}`}>
                This table has {total} orders
                </div> */}
        </h1>

        <div className={`${isactive===true? "visible" : "hidden"}`}>
        <div className="delete_img_card">
          < div className ="front">
            <div className="del_text">

              <span></span>

            </div>
            <div className="delete_icons">
               
               <div className="btn" onClick = {deletes} >
                <IconButton className={classes.root} aria-label="delete"  title = "served" >
                <CheckCircleIcon/>
                </IconButton>
               </div>
               <div className="btn" >
               <IconButton className={classes.root} aria-label="cancel" title = "back">
                <CancelIcon onClick  ={toggle} > </CancelIcon>
                </IconButton>
               </div>
             </div>
                </div>
                </div>
              </div>  
        </div> 
        </div>
    )
              }

else
{
  return (
    <div >
      <div
        className={`card ${order.length > 0 && order !== 'Here is your new table'? "flip" : ""}`} id={id}
        onClick={() => {
          if (order.length>0) { 
      
              setactive(!isactive);
        }
        }}
      >
        <div className="front">
          <p></p>
          <h3>Table{id} </h3>
        </div>

        <div className="back" id={id} title={order}>  
        <div className = "timer">
          
          <h4 className="msg"> {total} orders</h4>
          <h3 className ="number"> 
            {id}              
           </h3> 
           </div>
         </div>
      </div>
    </div>
    
  )
}
}
export default Image_card
