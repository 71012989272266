import React, { useState } from "react";
import "./Cards.css";
import { db } from "./firebase";
import firebase from "firebase";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {IconButton, makeStyles} from '@material-ui/core';
import Image_card from './Image_card';


function Cardflip({ id, order, index, user, total,time, order_list}) {
  const [isactive, setactive] =useState(false)
  const [color,setColor] = useState("#d5d5d5");
  
  const deletes = () => {
  
    if(total===1)
    {
    db.collection("Restaurant")
      .doc(user.uid)
      .collection("Branch")
      .doc(index)
      .update({
        "table" : "",
        order_time :  new firebase.firestore.Timestamp(1, 0),
      })
    }
    else
    {
      let i = order_list.indexOf(order);
      order_list.splice(i, 1);
      var del=order_list.join();
      db.collection("Restaurant")
      .doc(user.uid)
      .collection("Branch")
      .doc(index)
      .update({
        "table" : del
      })
      return false;
    }
  };
  const toggle = () => {
      setactive(!isactive);
    }

    
    const swap = () => {
      setColor("#FF0000");
    }


  const useStyles = makeStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  })

  const classes = useStyles();
  switch(order)
  {
    case "cheque" :
      return(
        <div>
        <Image_card image={'./bill.png'} id={id} order={order} user={user} index={index} total={total} time={time} order_list={order_list} />
        </div>
      )
      case "waiter" :
      return(
        <div>
        <Image_card image={'./waiter.png'} id={id} order={order} user={user} index={index} total={total} time={time} order_list={order_list} />
        </div>
      )
      case "spoon" :
      return(
        <div>
        <Image_card image={'./restaurant.png'} id={id} order={order} user={user} index={index} total={total} time={time} order_list={order_list} />
        </div>
      )
      case "water" :
      return(
        <div>
        <Image_card image={'./water.png'} id={id} order={order} user={user} index={index} total={total} time={time} order_list={order_list} />
        </div>
      )
      case "napkin" :
      return(
        <div>
        <Image_card image={'./napkin.png'} id={id} order={order} user={user} index={index} total={total} time={time} order_list={order_list} />
        </div>
      )
      default :

  }

 
  // pop up msg for cards
 if(total===1)
 {
  return (
    <div >
      <div
        className={`card ${order.length > 0 && order !== 'Here is your new table'? "flip" : ""}`} id={id}
        onClick={() => {
          if (order.length>0) { 
      
              setactive(!isactive);
        }
        }}
      >
        <div className="front">
          <p></p>
          <h3>Table{id} </h3>
        </div>

        <div className="back" id={id} title={order}>  
        <div className = "timer">
        
          
                
              <h3 className="msg">{order}</h3>
              <h3 className ="number"> 
              {id}
              {/* pops up to confirm delete card */}
              <div className={`${isactive===true? "visible" : "hidden"}`}>
              <div className="delete_card">
              < div className ="front">
              <div className="del_text">
              <h2> Have you served this order? </h2> 
              </div>
              <div className="delete_icons">
               
                <div className="btn" onClick = {deletes} title = "served">

                 <IconButton className={classes.root} color="inherit" aria-label="delete">
                 <CheckCircleIcon  />
                 </IconButton>
                </div>
                <div className="btn" onClick = {toggle} title = "back">
                <IconButton className={classes.root} color="inherit" aria-label="cancel">
                 <CancelIcon />
                 </IconButton>
                </div>
              </div>
              </div>
              </div>
              </div>  
          </h3> 
        
         
           </div>
         </div>
      </div>
    </div>
    
  )
}         
  else{
    return (
      <div >
        <div
          className={`card ${order.length > 0 && order !== 'Here is your new table'? "flip" : ""}`} id={id}
          onClick={() => {
            if (order.length>0) { 
        
                setactive(!isactive);
          }
          }}
        >
          <div className="front">
            <p></p>
            <h3>Table{id} </h3>
          </div>
  
          <div className="back" id={id} title={order}>  
          <div className = "timer">
            <h4 className="msg">{total} orders</h4>
            <h3 className ="number"> 
              {id}              
          </h3> 
             </div>
           </div>
        </div>
      </div>
      
    )
  }            

}

export default Cardflip;


