import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import Navbar from "./Navbar";
import Cards from "./Cards";
import firebase from "firebase";
import "./Home.css";
import "./Cards.css"
import Lottie from "react-lottie";
import moment from "moment";
import animationData from "./Animations/no_internet";
import "./Cards.css"
import {CgArrowsExchangeV} from "react-icons/cg"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import HomeQueue from "./homeQueue";

function Home({ user }) {
  const [posts, setPosts] = useState([]);
  const [display,setDisplay]=useState(false);
  let date = new Date('1980-01-1');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value,setvalue]= useState("")
  const [len,setlen]=useState(0);
   var current = new Date();
  var now = moment(current)

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    var not_selected = (document.querySelectorAll(`input[value="${value}"]:not(:checked)`));
    for(var i=0;i<not_selected.length;i++)
    {
      not_selected[i].checked=true;
    }
    setOpen(false);
    
  };
const accept=()=>{

  let accept = document.querySelectorAll('input[type=checkbox]:checked');
  //  const not_selected = document.querySelectorAll('input[type=checkbox]:not(:checked)');

 if(accept.length>0)
 {

  for(var i=0;i<accept.length;i++)
 
  {
    const uid= accept[i].id.split("_")
    const T_no=uid[0];
    const order_val=uid[1]

    let obj = posts.find(obj => obj.id === T_no);
    let x = obj.card.orders.indexOf(order_val);
    obj.card.orders.splice(x, 1);
    var del=obj.card.orders.join();
    

    if(obj.card.orders.length>=1)
    {
    db.collection("Restaurant")
    .doc(user.uid)
    .collection("Branch")
    .doc(T_no)
    .update({
      "table" : del
    })

    }
    else
    {
      db.collection("Restaurant")
      .doc(user.uid)
      .collection("Branch")
      .doc(T_no)
      .update({
        "table" : "",
        order_time :  new firebase.firestore.Timestamp(1, 0),
      })
    }
  }
accept=null;
 }
 
}

  useEffect(() => {
    db.collection("Restaurant")
      .doc(user.uid)
      .collection("Branch")
      .where("order_time", ">", date)
      .orderBy("order_time","asc")
      .onSnapshot((snapshot) => {
        setPosts(
          snapshot.docs.map((docu) => ({
            id: docu.id,
            card: docu.data(),
             differ : now.diff(moment(docu.data().order_time.toDate()), 'seconds'),
          }))
        );
      });
  }, []);


  

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
 


  if (navigator.onLine) {

    if(display===true)
    {
      if(posts.length===0)
      setDisplay(false)
      return(
        <div>
          <div>
            <Navbar username={user} />
          </div>
          <div className ={`${posts.length > 0 ? "home_btn" : "invisible"}`}>
          <div className ={`${posts.length > 0 ? "bell" : "invisible"}`}>
        
            <a> <div onClick = {()=> setDisplay(false)}className ={`${posts.length > 0 ? "change_black" : "invisible"}`}>     <CgArrowsExchangeV size="60px"/></div></a> 
       
          </div>
          </div>
    
            <div className="home_grid">
      {posts.map(({ card, id }) => {
          

          card.orders=card.table.split(',');
            return(
              <div class="boxes">
               <h2>{id}</h2>
               <br/> 
                  { card.orders.slice(0).reverse().map((text) => (
               <div className = "box_content"> 

                 <input className= "checkbox2" type="checkbox" id={id+"_"+text} value={text} onClick = { ()=> {
                    var this_cb  = (document.getElementById(id+"_"+text));
                    setvalue(this_cb.value)
                    var not_selected = (document.querySelectorAll(`input[value="${text}"]:not(:checked)`));
                    setlen(not_selected.length)
                    if(this_cb.checked===true&&not_selected.length)
                    {
                          setOpen(true)
                    }
                  
                 }}/>
                 <label for={id+"_"+text}>{text}</label>
               
                 
              </div>
               
               
                ))}
  
               </div>
                   );
          
         
        
      })}
     
          </div> 
          <div className ="accept">
          <a onClick = {accept} href="#" class="cross_line">
  Accept orders
</a>    
<Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"More tables with same order found!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <br/>
          there are {len} tables having <strong>{value}</strong> as order. Do you want to accept them too?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary" >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
          </div>
 
 </div>
      )
    }
    return (
      <div>
        <Navbar username={user} />
        <div className="home">

          <div className={`home_queue${posts.length > 0 ? "" : "_invisible"}`}>
       
            {posts.map(({ card,differ,id}) => {
              if(card.table.length>0)
              {
                return(
                  <div>
                 <HomeQueue id={card.id} index={id} time = {card.order_time} order_list = {card.table}/>
                 </div>
                )
              }
              
            })}
          </div>
     
          <div className ={`${posts.length > 0 ? "home_btn" : "invisible"}`}>
          <div className ={`${posts.length > 0 ? "bell" : "invisible"}`}>
        
            <a> <div onClick = {()=> setDisplay(true)}className ={`${posts.length > 0 ? "change" : "invisible"}`}>     <CgArrowsExchangeV size="60px"/></div></a> 
       
          </div>
          </div>
         
          
          <Cards user={user} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="offline">
        <Lottie options={defaultOptions} height={400} width={400} />
        <div className="no_internet_text">
          You need an active internet connection to keep using my services!
        </div>
      </div>
    );
  }
}

export default Home;